@use "../abstracts" as *;

.services__img-txt-card {
  border-radius: 5px;
  margin: auto;
  max-width: 400px;
  overflow: hidden;
  text-align: center;
}

.img-txt-card__img {
  width: 100%;
}

.img-txt-card__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 90%;
  min-height: 275px;
}

.img-txt-card__description > * {
  margin-bottom: 1rem;
}

.description__header {
  font-size: 2rem;
  font-weight: bold;
}

.description__paragraph {
  line-height: 1.75rem;
}

.description__link {
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 3px solid $black;
  padding-bottom: 0.5rem;
  &::after {
    content: " \2192";
    font-size: 1.25rem;
  }
  &:hover {
    cursor: pointer;
  }
}
