@use "../components/hamburger-toggle";
@use "./mobile-menu";

.nav-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

nav.row {
  display: none;
}

.company-logo {
  vertical-align: bottom;
}

/* Non-Mobile */
nav a {
  color: inherit;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}
