@use "../abstracts" as *;

nav.mobile {
  display: none;
}

nav.mobile.open {
  display: block;
  flex-basis: 100%;
}

nav.mobile a {
  font-size: 1.2rem;
  display: flex;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 1px;
  border-style: solid;
  min-height: 60px;
  border-color: $light-gray;
  align-items: center;
}

nav.mobile .drop {
  justify-content: space-between;
  text-decoration: none;
}

nav.mobile a.drop::after {
  content: "\25BC";
}

nav.mobile a.open::after {
  content: "\25B2";
}

nav.mobile .submenu {
  display: none;
}

nav.mobile .drop.open + .submenu {
  display: block;
  padding-left: 1em;
}

nav.mobile .submenu a::before {
  content: "\25B8";
  margin-right: 0.5em;
}
