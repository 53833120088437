@use "../abstracts/colors" as *;

.button {
  font-size: 1.25rem;
  padding: 1rem 2rem;
  font-weight: bold;
  color: $white;
  background-color: $primary-color;
  border-radius: 5px;
  &::after {
    content: "\f138";
    font-size: 1.25rem;
    font-family: "Font Awesome 5 Free";
    margin-left: .75rem;
    vertical-align: middle;
  }
  &:hover {
    cursor: pointer;
  }
}
