@use "../abstracts" as *;
@use "../vendors";

body {
  max-width: calc(100% - 2em);
  padding-top: 1.25rem;
  margin: auto;
  font-family: "Open Sans", sans-serif;
}

.pet-store {
  background-color: $light-peach;
}

.vet {
  background-color: $light-blue;
}
