@use "../abstracts" as *;

.slideshow.mobile img {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 2em;
  color: $light-gray !important;
}

.swiper-pagination-bullet-active {
  background: $primary-color !important;
}
