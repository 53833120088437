@use "../abstracts" as *;

.hamburger-box {
  background: $primary-color;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

/* Hamburger Menu Toggle*/
.hamburger {
  transition: transform 100ms;
}
.hamburger.rotate-45.active {
  transform: rotate(45deg);
}
.hamburger .line {
  fill: none;
  stroke: $white;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.hamburger .top {
  stroke-dasharray: 40 139;
}
.hamburger .bottom {
  stroke-dasharray: 40 180;
}
.hamburger.active .top {
  stroke-dashoffset: -98px;
}
.hamburger.active .bottom {
  stroke-dashoffset: -138px;
}
